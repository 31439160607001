import React, { useState } from "react";
import { MdDelete, MdEdit, MdSave } from "react-icons/md";
import { useGetDataQuery } from "../../../logic/apiSlice";
import apiService from "../../../logic/apiService";
import { toast } from "react-toastify";
export default function ResidentCard({ data }) {
    const imageInputRef = React.useRef();

    const { data: permissions = [] } = useGetDataQuery("/account/permissions");

    const [editBewoner, setEditBewoner] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);
    const [uploadingMedia, setUploadingMedia] = useState(false);
    const [localData, setData] = useState({
        name: data.name,
        image: data.profilepicture_path ? `${process.env.NODE_ENV === "development" ? 'http://localhost:3001/api/media' : 'https://dishmanagement.com/api/media'}/${data.profilepicture_path}` : null,
        profilepicture_path: data.profilepicture_path,
    });

    function onChange(e) {
        let value = e.target.value;

        if (e.target.type === 'checkbox')
            value = e.target.checked;

        setData(prev => ({
            ...prev,
            [e.target.name]: value
        }))
    }

    function saveResident(e) {
        e.preventDefault();

        if (uploadingMedia)
            return toast.error("Wacht tot de afbeelding is geüpload");

        // Check if all data has a value
        if (!localData.name)
            return toast.error("Velden mogen niet leeg gelaten worden");

        apiService.put(`/admin/bewoner/${data.residentID}`, localData).then((res) => {
            if (!res.success)
                return toast.error("Er is een onbekende fout opgetreden tijdens het opslaan van de bewoner");
            
            data.name = localData.name;
            data.profilepicture_path = localData.profilepicture_path;

            setEditBewoner(false);
            toast.success("Bewoner is opgeslagen");
        });
    }

    function deleteResident(e) {
        e.preventDefault();

        apiService.delete(`/admin/bewoner/${data.residentID}`).then((res) => {
            if (!res.success)
                return toast.error("Er is een onbekende fout opgetreden tijdens het verwijderen van de bewoner");

            toast.success("Bewoner is verwijderd");
            setIsDeleted(true);
        });
    }

    async function uploadImage(e) {
        setUploadingMedia(true);
        const file = e.target.files[0];

        // Is there a file? No? Stop.
        if (!file) {
            setUploadingMedia(false);
            return;
        }
        
        // Check size
        if (file.size > 16 * 1000 * 1000) { // 16MB
            toast.error("Bestand mag niet groter zijn dan 16MB");
            setUploadingMedia(false);
            return;
        }

        // Check type
        if (!file.type.includes('image')) {
            toast.error("Bestand moet een afbeelding zijn. kies een ander bestand");
            setUploadingMedia(false);
            return;
        }

        // Set image
        setData(prev => ({
            ...prev,
            image: URL.createObjectURL(file),
        }));

        // Upload image
        const formData = new FormData();
        formData.append('media', file);

        apiService.post(`/admin/bewoner/upload`, formData).then((res) => {
            if (res.success) {
                setData(prev => ({
                    ...prev,
                    profilepicture_path: res.path
                }));
                setUploadingMedia(false);
            } else {
                toast.error("Er is een onbekende fout opgetreden tijdens het uploaden van de afbeelding");
                setUploadingMedia(false);
            }
        });
    }

    if (isDeleted) 
        return null;


    return (
        <div 
            className="border-b-light-primary flex min-h-20 w-full max-w-3xl flex-col gap-2.5 border-b px-5 py-3 last:border-b-0 sm:flex-shrink-0 sm:last:mb-0 transition-all duration-300"
        >
            {/* Content */}
            <div className="flex flex-row gap-2.5">

                {/* Content */}
                <div className="flex w-full flex-col gap-2">

                    {/* Header */}
                    <div className="flex flex-row gap-2">

                        {/* Profile Image */}
                        <div className="size-12">
                            {data.profilepicture_path ? <img className="size-12 rounded-full object-cover aspect-[1/1]" loading="lazy" src={`${process.env.NODE_ENV === "development" ? 'http://localhost:3001/api/media' : 'https://dishmanagement.com/api/media'}/${data.profilepicture_path}`} alt="Profile" /> : <div className="size-12 rounded-full bg-gray-400 animate-pulse"></div>}
                        </div>

                        <div>
                            <div className="flex w-full flex-row items-center gap-2">
                                <h5 className="text-base font-bold">
                                    {data.name}
                                </h5>
                                <div>
                                    <MdEdit 
                                        onClick={() => setEditBewoner(!editBewoner)}
                                        className="text-light-secondary size-5 hover:size-6 transition-all duration-300" 
                                    />
                                </div>
                            </div>
                            <div className="flex w-full flex-row items-center gap-2">
                                <span className="text-light-text text-sm">
                                    {data.reportCount} belevingen
                                </span>
                            </div>
                        </div>

                        
                    </div>

                    {/* Editing */}
                    <div className={`grid duration-500 ease-in-out ${editBewoner ? "grid-rows-animate-height-open" : "grid-rows-animate-height-closed"}`}>
                        <div className="overflow-hidden w-full flex flex-col">
                            <div className="w-full flex flex-col sm:flex-row gap-5 mb-4">
                                <div className="w-full flex flex-col">
                                    <p className="text-sm">Naam</p>
                                    <input
                                        className="focus:border-light-secondary bg-light-bg border-light-primary h-8 rounded border p-2 outline-none text-base mb-1"
                                        name="name"
                                        value={localData.name}
                                        onChange={(e) => onChange(e)}
                                        placeholder="Naam"
                                        type="text"
                                        tabIndex={1}
                                    />
                                </div>

                                <div className="w-full">
                                    <div className="flex w-full flex-row items-center gap-2">
                                        <span className="text-light-text text-sm">Profielfoto</span>
                                        <span className="text-light-text text-sm">&#8226;</span>
                                        <span className="text-light-text text-sm">Klik om te bewerken</span>
                                    </div>

                                    {localData.image 
                                        ? <img className="w-full object-contain rounded cursor-pointer" src={localData.image} alt="Profile" onClick={() => imageInputRef.current.click()} loading="lazy"/> 
                                        : <div className="w-full aspect-[1/1] rounded bg-gray-400 animate-pulse cursor-pointer flex justify-center items-center"   onClick={() => imageInputRef.current.click()}>
                                            <p>Klik hier om een profiel foto te selecteren</p>
                                        </div>
                                    }
                                    <input
                                        className="hidden"
                                        ref={imageInputRef}
                                        name="image"
                                        onChange={(e) => uploadImage(e)}
                                        placeholder="Upload een afbeelding"
                                        type='file'
                                        accept='image/*'
                                    />
                                </div>
                            </div>



                            <div className="flex-col md:flex-row flex justify-between items-center gap-5">

                                {/* Save */}
                                <button
                                    type="submit"
                                    onClick={(e) => saveResident(e)}
                                    tabIndex={permissions.length + 4}
                                    className={`w-full flex justify-center items-center gap-1 bg-light-secondary text-light-text h-12 px-4 rounded font-bold hover:bg-light-accent transition-all duration-300 ${uploadingMedia ? 'cursor-not-allowed animate-pulse' : ''}`}
                                >
                                    <MdSave className="fill-light-text size-5"/> Opslaan
                                </button>

                                {/* Delete */}
                                <button
                                    onClick={(e) => deleteResident(e)}
                                    type="button"
                                    tabIndex={permissions.length + 6}
                                    className="w-full flex justify-center items-center gap-1 bg-light-secondary text-light-text h-12 px-4 rounded font-bold hover:bg-light-accent transition-all duration-300"
                                >
                                    <MdDelete className="fill-light-text size-5"/> Verwijderen
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}