import { toast } from "react-toastify";

class apiService {
    constructor() {
        if (process.env.NODE_ENV === "development")
            this.default_url = "http://localhost:3001/api";
        else 
            this.default_url = "https://dishmanagement.com/api";
    }

    async get(url) {
        const response = await fetch(this.default_url + url, {
            method: "GET",
            credentials: "include",
        });
        this.checkAuthStatus(response.status);

        // Check the Content-Type to determine if it's JSON or binary data
        const contentType = response.headers.get("Content-Type");
        
        if (contentType && contentType.includes("application/json")) {
            let result = await response.json();
            
            this.checkErrors(result);
            return result
        } else {
            return await response.blob();
        }
    }

    async post(url, body = {}) {
        let options = {
            method: "POST",
            credentials: "include",
        };
    
        // Check if the body is FormData
        if (body instanceof FormData) {
            options.body = body;  // FormData is used directly as the body
        } else {
            options.headers = { "Content-Type": "application/json" };
            options.body = JSON.stringify(body);  // Body is stringified JSON
        }
    
        const response = await fetch(this.default_url + url, options);
        let result = await response.json();
        this.checkAuthStatus(response.status);
        this.checkErrors(result);

        return result;
    }

    async put(url, body = {}) {
        const response = await fetch(this.default_url + url, {
            method: "PUT",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
        let result = await response.json();
        
        this.checkAuthStatus(response.status);
        this.checkErrors(result);
        
        return result;
    }

    async delete(url, body = {}) {
        const response = await fetch(this.default_url + url, {
            method: "DELETE",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
        let result = await response.json();

        this.checkAuthStatus(response.status);
        this.checkErrors(result);

        return result;
    }
    
    checkAuthStatus(status) { 
        // This check is for when user isn't authenticated, so will send them back to login
        if (status === 401)
            window.location.href = '/auth/login';
    }

    checkErrors(result) {
        if (!result.success) {
            if (result.message === "FORBIDDEN")
                toast.error("Je hebt geen rechten om dit uit te voeren.");
            if (result.message === "INVALID_BODY")
                toast.error("Velden mogen niet leeg gelaten worden")
        }
    }
}

export default new apiService();
